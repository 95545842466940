<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-calendar"></i> 服装
                </el-breadcrumb-item>
                <el-breadcrumb-item>属性管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div style="border-bottom: 1px solid #ddd;padding-bottom: 10px;margin-bottom: 20px">
                <el-button type="success" plain icon="el-icon-edit" @click="_handleAddAttr">添加新属性</el-button>

            </div>
            <div>
                <el-form ref="form" :model="form" label-width="160px" border>

                    <el-form-item :label="param.attrName" v-for="param in params" :key="param.id">
                        <div>
                            <el-tag
                                    v-for="(item ) in param.availableVal"
                                    :key="item"
                                    size="small"
                                    closable
                                    @close="handleNewAttrClose(param,item)"
                                    effect="plain">
                                {{ item }}
                            </el-tag>
                            <el-input
                                    class="input-new-tag"
                                    v-if="param.tagInputVisible"
                                    v-model="param.inputValue"
                                    ref="saveTagInput"
                                    size="small"
                                    @keydown.enter="handleInputConfirm(param)"
                                    @blur="handleInputConfirm(param)"
                            >
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="showTagInput(param)">+ 新属性值
                            </el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div style="text-align: center">

                <!--<el-button type="primary" plain round>完成</el-button>-->
                <el-button type="primary" plain round @click="_onSubmit">完成</el-button>
            </div>
        </div>


        <!-- 编辑弹出框 -->
        <el-dialog title="添加新属性" v-model="editVisible" width="50%">
            <el-form ref="form" :model="form" label-width="120px">
                <el-form-item label="名称">
                    <el-input v-model="newParam.attrName"></el-input>
                </el-form-item>
                <el-form-item label="可选值">
                    <el-tag
                            v-for="item in newParam.availableVal"
                            :key="item"
                            size="small"
                            closable
                            @close="handleNewAttrClose(newParam,item)"
                            effect="plain">
                        {{ item }}
                    </el-tag>
                    <el-input
                            class="input-new-tag"
                            v-if="newParam.tagInputVisible"
                            v-model="newParam.inputValue"
                            ref="saveTagInput"
                            size="small"
                            @keydown.enter="handleInputConfirm(newParam)"
                            @blur="handleInputConfirm(newParam)"
                    >
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showTagInput(newParam)">+ 新属性值
                    </el-button>
                </el-form-item>
                <el-form-item label="唯一值">
                    <el-switch
                            v-model="newParam.isOnly"
                            active-color="#13ce66"
                            active-value="0"
                            inactive-value="1">
                    </el-switch>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addNewParam">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import api from '../api/api'
    import {ElMessage} from 'element-plus';

    export default {
        name: 'AttrManage',
        data() {
            return {
                editVisible: false,
                params: [],
                newAttrInputVisible: false,
                newParam: {
                    availableVal: []
                }
            };
        },
        methods: {
            _onSubmit() {
                let data = [];
                for (let i in this.params) {
                    let item = this.params[i];
                    if (JSON.stringify(item.availableVal) != item.availableVals) {
                        data.push({'attrId': item.id, 'availableVal': item.availableVal})
                    }
                }
                if (data.length === 0) {
                    ElMessage({type: 'warning', message: "请先修改参数再进行提交"});
                    return;
                }
                api.put("/clothing/attr", data)
                    .then(res => {
                        console.log(res);
                        if (res.code === 0) {
                            ElMessage({type: 'success', message: "提交成功"});
                        }
                    }).catch(err => {
                    // ElMessage({type: 'error', message: "提交失败" + err});
                    console.log(err)
                });
            },
            _handleAddAttr() {
                this.editVisible = true;
            },
            /**
             * 显示属性输入栏
             */
            showTagInput(item) {
                item.tagInputVisible = true;
                this.$nextTick(() => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            /**
             * 处理新输入的属性
             */
            handleInputConfirm(param) {
                console.log("param，", param)
                let inputValue = param.inputValue;
                if (inputValue && inputValue.length > 0)
                    inputValue = inputValue.toLocaleUpperCase();
                else {
                    return;
                }
                console.log(param.availableVal.indexOf(inputValue));
                if (inputValue && param.availableVal.indexOf(inputValue) === -1) {
                    param.availableVal.push(inputValue);
                }
                param.tagInputVisible = false;
                param.inputValue = '';
            },
            /**
             * 移除某属性值
             */
            handleNewAttrClose(param, tag) {
                if (tag)
                    param.availableVal.remove(tag);
            },
            /**
             * 上传新的服装参数
             */
            addNewParam() {
                console.log(this.newParam)
                api.post("/clothing/attr", this.newParam)
                    .then(res => {
                        console.log(res);
                        if (res.code === 0) {
                            ElMessage({type: 'success', message: "添加成功"});
                            this.editVisible = false;
                        }
                    }).catch(err => {
                    ElMessage({type: 'error', message: "获取属性列表失败" + err});
                });
            },
            /**
             * 获取服装所有规格参数
             * @private
             */
            _getClothParams() {
                api.get("/clothing/attrs")
                    .then(res => {
                        console.log(res);
                        if (res.code === 0) {
                            res.data.records.forEach(item => {
                                item['availableVal'] = JSON.parse(item.availableVals);
                            });
                            this.params = res.data.records;
                        }
                    }).catch(err => {
                    ElMessage({type: 'error', message: "获取属性列表失败" + err});
                });
            }
        },
        mounted() {
            this._getClothParams();
        }
    };
</script>

<style>
    .el-tag {
        margin: 0 10px;
    }

    .el-form-item__label {
        font-weight: 700;
        font-size: 15px;
    }
</style>
